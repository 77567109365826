import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import { StrongGold } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 48",
  week: "Semana 7",
  month: "abr",
  day: "30",
  monthNumber: "04",
  date: "2020-04-30",
  path: "/cronologia/semana-07#dia-30-abr",
};

const Day48 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModCovidHighlight>
          La C. Valenciana supera los 12.000 confirmados
        </ModCovidHighlight>
        <ModCovidHighlight>
          Castilla y León supera los 20.000 confirmados
        </ModCovidHighlight>
        <ModCovidHighlight>
          Castilla La Mancha supera los 20.000 confirmados
        </ModCovidHighlight>
        <ModDataEu fecha={"2020-04-30"} />
        <ModLethalityEu fecha={"2020-04-30"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.261 nuevos positivos y 268 personas fallecidas.
          Permanecen hospitalizadas 116.661 personas, 816 más que el día
          anterior, y 3.103 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.776, lo que supone un aumento de 55 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,3 % y la tasa de recuperados del 46,8 %.
        </ModText>
        <ModDailyHighlight>
          <strong>El PIB Español </strong> sufre un desplome del{" "}
          <StrongGold>5,2%</StrongGold> en el primer trimestre.
        </ModDailyHighlight>
        <ModText>
          El <strong>Plan para la Transición hacia una nueva normalidad</strong>{" "}
          contempla 4 fases, de al menos 2 semanas, para recuperar
          paulatinamente la vida cotidiana y la actividad económica. Se hará en
          función del control de la epidemia en cada provincia y a la velocidad
          que permita la situación sanitaria de la zona.
        </ModText>
        <ModText>
          En este{" "}
          <a
            href="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Documents/2020/28042020_Anexo%20II%20FASES.pdf"
            target="_blank" 
 rel="noopener noreferrer"          >
            documento
          </a>
          se recogen las diferentes medidas establecidas en cada una de las
          etapas y el ámbito de actividad para cada una de ellas.
        </ModText>
        <ModImage
          src="/images/svg/02_abr_mascarilla-w.svg"
          alt="mascarillas quirúrgicas"
        />
        <ModText>
          Hoy ha comenzado la producción contratada por el Gobierno de{" "}
          <strong>10 millones de mascarillas quirúrgicas al mes</strong>.
          Empresas españolas confeccionarán mascarillas higiénicas a gran escala
          para distribuir en centros comerciales y farmacias.
        </ModText>
        <ModText>
          Por otra parte, desde el comienzo de la crisis del COVID-19,
          Exteriores ha llevado a cabo la{" "}
          <strong>
            mayor operación de retorno de españoles de la historia
          </strong>
          : ha facilitado la vuelta de 25.000 viajeros y ha coordinado alrededor
          de 40 vuelos.
        </ModText>
        <ModText>
          En cuanto al ámbito económico, más de{" "}
          <strong>
            1,1 millones de autónomos reciben hoy la prestación extraordinaria
          </strong>{" "}
          por cese de actividad. Las comunidades autónomas donde se han
          registrado más solicitudes son Andalucía, con 18,66% del total,
          Cataluña (16,10%), Madrid (12,68%) y la Comunidad Valenciana (11,83%).
        </ModText>
        <ModGraph
          name="30_abr-mapa-spain"
          alt="mapa españa prestación a autónomos"
        />
        <ModText>
          <strong>Ministerio de Agricultura, Pesca y Alimentación</strong>
          ha publicado en el Boletín Oficial del Estado la Orden que amplia,
          hasta el 15 de junio, el plazo de presentación de la solicitud única
          de las <strong>ayudas directas de la Política Agraria Común</strong>
          (PAC) para el año 2020.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day48;
